import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import BigNumber from "bignumber.js";

const BSCSCAN_API_URL = "https://api.basescan.org/api";
const API_KEY = process.env.REACT_APP_BSCSCAN_API_KEY; // Replace with your actual API key

const tokenContracts = {
  "0x93c736113780Dd1780f2B6c7ce932562dee1256F": "POWERLINK",
  "0x4200000000000000000000000000000000000006": "WETH",
};

function formatBalance(balance, decimals = 18) {
  return new BigNumber(balance)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toFixed();
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function BalancesTable() {
  const [balances, setBalances] = useState([]);
  const [totals, setTotals] = useState({ RECORD: 0, USDC: 0, ETH: 0 });

  async function getEthBalance(address) {
    const params = new URLSearchParams({
      module: "account",
      action: "balance",
      address,
      apiKey: API_KEY,
    });

    const response = await axios.get(`${BSCSCAN_API_URL}?${params}`);
    return response.data.result;
  }

  async function getTokenBalance(address, contractAddress) {
    const params = new URLSearchParams({
      module: "account",
      action: "tokenbalance",
      contractaddress: contractAddress,
      address,
      tag: "latest",
      apiKey: API_KEY,
    });

    const response = await axios.get(`${BSCSCAN_API_URL}?${params}`);
    return response.data.result;
  }

  const checkBalances = useCallback(
    async () => {
      const addresses = [
        "0x0A7A9e7A086a917470143be0De6C2E49a49205a5",
        "0x795D58524d0F144E78Eb0AdA1542aCFeFDB1A529",
        "0xfAe3D2b260f8238C6963e6df65BCDDDA67860C1E",
        "0xa628d10E348CE03375B3C34c796788a1D637966D",
        "0xD9f7be58713262EcC2b4045afC3480f34B693477",
        "0x9bE115823B1821975F4Ac347f6a1c27A939cD20a",
        "0xE25D318cfD13f9d2446d773447dbD86E5CC957Fd",
        "0xe3e1Acef8bae83452f68E9865E764D533bD87D15",
        "0xbaDE1141E91EE689fC31494A68a819cb5B717D02",
        "0x5c12339821b493b03E8E91952F61e02B3A974a9b",
        "0x4e7d1A5Ff3aB77017b7b5F5b9223D1385962B047",
        // "0xD55E855794861A9d1e5CCFCf25e4B64fbd36d572",
        // "0x8Cff3458B5e07D7EeCEd82b3EEA64AD95E040e3d",
        // "0xa11f0182caEcE266bec4c8e262E627e711b51BfB",
        // "0xbd00b5e3a57Da65AB92b14ef0E922c5a4Dd7dc01",
        // "0x6127c62EB4a28feF2D940dC6d2beBb613058Efd1",
        // "0xc6F9D6144A36070792Ee69a8ebB546fC8a0bDdCf",
        // "0xa704fCf6bb27364Ecf591D33e543664A7ad663e2",
      ];

      let balancesData = [];
      let requestCount = 0;

      for (let address of addresses) {
        let addressBalances = {
          address: address,
          ETH: null,
          tokens: {},
        };

        const ethBalanceWei = await getEthBalance(address);
        addressBalances.ETH = formatBalance(ethBalanceWei, 18);

        for (const [contractAddress, tokenName] of Object.entries(
          tokenContracts
        )) {
          const tokenBalanceWei = await getTokenBalance(
            address,
            contractAddress
          );
          addressBalances.tokens[tokenName] = formatBalance(
            tokenBalanceWei,
            tokenName === "USDC" ? 6 : 18
          );
        }

        balancesData.push(addressBalances);

        requestCount++;

        if (requestCount % 5 === 0) {
          await delay(1000); // Wait for 1 second after every 5 requests
        }
      }

      setBalances(balancesData);

      // Calculate totals
      const totals = balancesData.reduce(
        (acc, balance) => {
          acc.ETH += parseFloat(balance.ETH);
          for (const token in balance.tokens) {
            if (!acc[token]) acc[token] = 0;
            acc[token] += parseFloat(balance.tokens[token]);
          }
          return acc;
        },
        { RECORD: 0, USDC: 0, ETH: 0 }
      );

      setTotals(totals);
    },
    [
      /* dependencies */
    ]
  );

  useEffect(() => {
    checkBalances().catch(console.error);
  }, [checkBalances]);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Address</th>
            <th>ETH</th>
            {/* Dynamically create columns for tokens */}
            {balances[0] &&
              Object.keys(balances[0].tokens).map((token) => (
                <th key={token}>{token}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {/* Map over balances to create rows */}
          {balances.map((balance) => (
            <tr key={balance.address}>
              <td>{balance.address}</td>
              <td>{parseFloat(balance.ETH).toFixed(2)}</td>
              {/* Dynamically create cells for tokens */}
              {Object.entries(balance.tokens).map(
                ([token, tokenBalance], index) => (
                  <td key={index}>{parseFloat(tokenBalance).toFixed(2)}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>Total</strong>
            </td>
            <td>{totals.ETH.toFixed(2)}</td>
            {/* Assuming the tokens are always PEPECASH and WBNB in this order */}
            <td>{totals.RECORD.toFixed(2)}</td>
            <td>{totals.USDC.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default BalancesTable;
